@use '@carbon/react/scss/themes' as *;
@use '@carbon/react/scss/theme' with ($theme: $white);
@use '@carbon/react/index.scss' as *;

// Custom green palette
$green-primary: #72cc50;
$green-dark: #2D7D66;
$green-darker: #1B4D3E;
$green-light: #90d674;
$green-lighter: #e8f5e5;
$green-hover: #66b847;

$brand-green: #427963; // The green color from your header
$brand-light-green: #72cc50;
$brand-lighter-green: #e8f5e5;

.landing-page {
    .cds--header {
        background-color: $brand-green;
        border-bottom: none;
        height: 64px;

        .cds--header__name {
            color: $white-0;
            font-size: 1.2rem;
            font-weight: 400;
            height: 64px;
            display: flex;
            align-items: center;

            img {
                height: 100px;
                margin-right: 8px;
            }
        }

        .cds--header__menu-item {
            color: $white-0;
            height: 64px;
            line-height: 64px;
            background-color: $brand-green;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }

    .landing-content {
        padding-top: 3rem;
    }

    .hero-content {
        padding: 4rem 1rem;
        text-align: center;
        background: linear-gradient(135deg, $white-0 50%, $green-lighter 50%, $white-0 50%);

        h1 {
            font-size: 3rem;
            font-weight: 600;
            margin-bottom: 1rem;
            color: $green-darker;
        }

        p {
            font-size: 1.25rem;
            margin-bottom: 2rem;
            color: $green-dark;
        }

        .cds--btn {
            background-color: $green-primary;
            color: $white-0;

            &:hover {
                background-color: $green-hover;
            }
        }
    }

    .video-container {
        padding: 2rem;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;

        video {
            width: 100%;
            height: auto;
            border-radius: 12px;
            box-shadow:
                0 20px 40px rgba($green-darker, 0.15),
                0 10px 20px rgba($green-darker, 0.1);
            background-color: $white-0;
            border: 1px solid rgba($green-primary, 0.1);
            transition: transform 0.3s ease, box-shadow 0.3s ease;

            &:hover {
                transform: translateY(-5px);
                box-shadow:
                    0 25px 50px rgba($green-darker, 0.2),
                    0 15px 25px rgba($green-darker, 0.15);
            }
        }
    }

    .landing-features {
        padding: 4rem 2rem;
        background-color: $green-lighter;
    }

    .feature-tile {
        height: 100%;
        padding: 2rem;
        margin-bottom: 2rem;
        background-color: $white-0;
        border: 1px solid rgba($green-primary, 0.2);
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &:hover {
            transform: translateY(-4px);
            box-shadow: 0 4px 16px rgba($green-primary, 0.15);
        }

        .feature-icon {
            margin-bottom: 1rem;
            color: $green-primary;
        }

        h3 {
            margin-bottom: 1rem;
            color: $green-darker;
            font-weight: 600;
        }

        p {
            color: $green-dark;
        }
    }

    .landing-pricing {
        padding: 4rem 2rem;
        background-color: $white-0;
    }

    .pricing-tile {
        height: 100%;
        padding: 2rem;
        margin-bottom: 2rem;
        background-color: $white-0;
        border: 1px solid rgba($green-primary, 0.2);
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &:hover {
            transform: translateY(-4px);
            box-shadow: 0 4px 16px rgba($green-primary, 0.15);
        }

        &.featured {
            border: 2px solid $green-primary;
            background: linear-gradient(160deg, $green-lighter 0%, $white-0 100%);
        }

        .pricing-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;

            h3 {
                color: $green-darker;
                font-weight: 600;
            }
        }

        .pricing-price {
            margin: 2rem 0;
            font-size: 2.5rem;
            font-weight: 600;
            color: $green-darker;

            .currency {
                font-size: 1.5rem;
                vertical-align: super;
            }

            .period {
                font-size: 1rem;
                color: $green-dark;
                font-weight: normal;
            }
        }

        .pricing-features {
            list-style: none;
            padding: 0;
            margin: 2rem 0;

            li {
                margin-bottom: 0.5rem;
                padding-left: 1.5rem;
                position: relative;
                color: $green-dark;

                &::before {
                    content: "✓";
                    position: absolute;
                    left: 0;
                    color: $green-primary;
                }
            }
        }

        .pricing-button {
            width: 100%;
            margin-top: 2rem;

            &.cds--btn--primary {
                background-color: $green-primary;
                color: $white-0;

                &:hover {
                    background-color: $green-hover;
                }
            }

            &.cds--btn--tertiary {
                color: $green-dark;
                border-color: $green-primary;

                &:hover {
                    background-color: $green-lighter;
                }
            }
        }
    }

    .landing-footer {
        padding: 2rem;
        text-align: center;
        background-color: $green-lighter;

        p {
            color: $green-dark;
        }

        .footer-links {
            margin-top: 1rem;

            a {
                margin: 0 1rem;
                color: $green-dark;
                text-decoration: none;

                &:hover {
                    color: $green-darker;
                    text-decoration: underline;
                }
            }
        }
    }

    // Override Carbon button styles
    .cds--btn--primary {
        background-color: $green-primary;

        &:hover {
            background-color: $green-hover;
        }
    }

    .cds--btn--tertiary {
        color: $green-dark;
        border-color: $green-primary;

        &:hover {
            color: $green-darker;
            background-color: $green-lighter;
        }
    }

    .cds--tag {
        background-color: $green-primary;
        color: $white-0;
    }
}

// Additional utility classes
.text-center {
    text-align: center;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mb-8 {
    margin-bottom: 2rem;
}

.section-title {
    font-size: 2rem;
    font-weight: 600;
    color: $green-darker;
    text-align: center;
    margin-bottom: 3rem;
}

.login-page {
    min-height: 100vh;
    background-color: $white-0;
    display: flex;
    flex-direction: column;

    .login-header {
        background-color: $brand-green;
        border-bottom: none;
        height: 64px;

        .cds--header__name {
            color: $white-0;
            font-size: 1.2rem;
            font-weight: 400;
            height: 64px;
            display: flex;
            align-items: center;

            img {
                height: 32px;
                margin-right: 8px;
            }
        }
    }

    .login-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        min-height: calc(100vh - 64px); // Subtract header height
    }

    .login-content {
        width: 100%;
        max-width: 450px;
        padding: 2.5rem;
        background-color: $white-0;
        border-radius: 8px;
        box-shadow: 0 4px 24px rgba($green-darker, 0.1);
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &:hover {
            transform: translateY(-4px);
            box-shadow: 0 8px 32px rgba($green-darker, 0.15);
        }

        .login-header-content {
            text-align: center;

            h1 {
                font-size: 2rem;
                font-weight: 600;
                color: $green-darker;
                margin-bottom: 0.5rem;
            }

            p {
                color: $green-dark;
                font-size: 1rem;
            }
        }

        .cds--text-input__field-wrapper {
            background-color: $white-0;

            .cds--text-input__invalid-icon {
                fill: $green-primary;
            }
        }

        .cds--text-input {
            background-color: $white-0;
            border-bottom: 1px solid $green-dark;

            &:focus {
                outline: 2px solid $green-primary;
                outline-offset: -2px;
            }
        }

        .cds--text-input__label {
            color: $green-darker;
        }

        .cds--btn {
            width: 100%;
            justify-content: center;
            background-color: $green-primary;
            color: $white-0;

            &:hover {
                background-color: $green-hover;
            }

            svg {
                fill: currentColor;
            }
        }

        .error-message {
            color: #da1e28;
            font-size: 0.875rem;
            margin-top: 0.5rem;
        }

        .forgot-password {
            text-align: right;
            margin: 0.5rem 0;

            a {
                color: $green-dark;
                font-size: 0.875rem;
                text-decoration: none;

                &:hover {
                    color: $green-darker;
                    text-decoration: underline;
                }
            }
        }

        .signup-prompt {
            text-align: center;
            margin-top: 1rem;
            color: $green-dark;

            a {
                color: $green-primary;
                font-weight: 500;
                text-decoration: none;

                &:hover {
                    color: $green-hover;
                    text-decoration: underline;
                }
            }
        }
    }

    // Carbon overrides
    .cds--text-input__field-wrapper[data-invalid]>.cds--text-input--invalid {
        outline: 2px solid #da1e28;
    }

    .cds--text-input__field-wrapper[data-invalid]>.cds--text-input__invalid-icon {
        fill: #da1e28;
    }

    .cds--link {
        color: $green-primary;
        text-decoration: none;

        &:hover {
            color: $green-hover;
        }
    }
}

@media (max-height: 700px) {
    .login-container {
        padding: 1rem;
    }

    .login-content {
        padding: 2rem;
    }
}

.signup-page {
    min-height: 100vh;
    background-color: $white-0;
    display: flex;
    flex-direction: column;

    .signup-header {
        background-color: $brand-green;
        border-bottom: none;
        height: 64px;

        .cds--header__name {
            color: $white-0;
            font-size: 1.2rem;
            font-weight: 400;
            height: 64px;
            display: flex;
            align-items: center;

            img {
                height: 32px;
                margin-right: 8px;
            }
        }
    }

    .signup-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        min-height: calc(100vh - 64px);
    }

    .signup-content {
        width: 100%;
        max-width: 450px;
        padding: 2.5rem;
        background-color: $white-0;
        border-radius: 8px;
        box-shadow: 0 4px 24px rgba($green-darker, 0.1);
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &:hover {
            transform: translateY(-4px);
            box-shadow: 0 8px 32px rgba($green-darker, 0.15);
        }

        .signup-header-content {
            text-align: center;

            h1 {
                font-size: 2rem;
                font-weight: 600;
                color: $green-darker;
                margin-bottom: 0.5rem;
            }

            p {
                color: $green-dark;
                font-size: 1rem;
            }
        }

        .cds--text-input__field-wrapper {
            background-color: $white-0;
        }

        .cds--text-input {
            background-color: $white-0;
            border-bottom: 1px solid $green-dark;

            &:focus {
                outline: 2px solid $green-primary;
                outline-offset: -2px;
            }
        }

        .cds--text-input__label {
            color: $green-darker;
        }

        .cds--form__helper-text {
            color: $green-dark;
        }

        .cds--btn {
            width: 100%;
            justify-content: center;
            background-color: $green-primary;
            color: $white-0;

            &:hover {
                background-color: $green-hover;
            }

            svg {
                fill: currentColor;
            }
        }

        .cds--inline-notification {
            margin-bottom: 1rem;

            .cds--inline-notification__title {
                margin-right: 0.5rem;
            }
        }

        .login-prompt {
            text-align: center;
            margin-top: 1rem;
            color: $green-dark;

            a {
                color: $green-primary;
                font-weight: 500;
                text-decoration: none;

                &:hover {
                    color: $green-hover;
                    text-decoration: underline;
                }
            }
        }
    }

    // Helper text styles
    .cds--form__helper-text {
        margin-top: 0.25rem;
        font-size: 0.75rem;
    }
}

@media (max-height: 800px) {
    .signup-container {
        padding: 1rem;
    }

    .signup-content {
        padding: 2rem;
    }
}